import { useTranslation } from "react-i18next";
import { Menu } from "./menu"
import { Link } from "react-router-dom";

export const Footer = (props: { OnSelectMenuItem: (item: string) => void }) => {
    const { t } = useTranslation();
    const today = new Date();

    return <>
        <footer>
            <div className="container">
                <div className="">
                    <div className="footer_col logo_col">
                        <div className="logo">
                            <img src={'/logo.svg'} alt={'logo'} />
                            <div className="name_group">
                                <span className="name">Residium Software</span>
                                <span className="slogan">Business empowered, excellence delivered </span>
                            </div>
                        </div>
                        <p>{t('Footer.Text')}</p>
                    </div>

                    <div className="footer_col footer_menu">
                        <div className="col_title">{t('Footer.Labels.Menu')}</div>
                        <ul>
                            <Menu OnSelectMenuItem={props.OnSelectMenuItem} />
                        </ul>
                    </div>
                    <div className="footer_col footer_contact">
                        <div className="col_title">{t('Footer.Labels.Address')}</div>
                        <ul>
                            <li className="li_address">
                                <div>
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2411 36.1176L30.3104 58.3321C31.0956 59.572 32.9044 59.572 33.6896 58.3322L47.7589 36.1176C49.876 32.7747 51 28.8993 51 24.9424V23.9414C51 17.6351 47.8711 11.7398 42.6483 8.20559C36.2165 3.85329 27.7835 3.85329 21.3517 8.20559C16.1289 11.7398 13 17.6351 13 23.9414V24.9424C13 28.8993 14.124 32.7747 16.2411 36.1176Z" stroke="white" strokeWidth="5" />
                                        <circle cx="32" cy="24" r="7" stroke="white" strokeWidth="3" />
                                    </svg>

                                    <span>
                                        <span>T. Narbuto 5, Vilnius,</span>
                                        <span>LT-08103 Lithuania</span>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_3_406)">
                                            <path d="M14.3892 5.41419L25.8952 16.9202C26.6763 17.7013 26.6763 18.9676 25.8952 19.7486L19.3939 26.2499C18.7308 26.9131 18.6227 27.9501 19.1348 28.7357L19.2961 28.9831C23.3859 35.2569 28.755 40.5958 35.0517 44.6502C35.8168 45.1429 36.8219 45.0353 37.4654 44.3918L44.0019 37.8553C44.7829 37.0742 46.0493 37.0742 46.8303 37.8553L58.3363 49.3613C59.1174 50.1424 59.1174 51.4087 58.3363 52.1897L56.8669 53.6591C51.5134 59.0127 43.1403 59.8811 36.8021 55.7401L35.1859 54.6842C24.7671 47.8772 15.8733 38.9834 9.06632 28.5646L8.01044 26.9485C3.86945 20.6102 4.73783 12.2371 10.0914 6.88358L11.5608 5.41419C12.3418 4.63314 13.6082 4.63314 14.3892 5.41419Z" stroke="white" strokeWidth="5" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3_406">
                                                <rect width="64" height="64" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    +370 691 9868
                                </div>
                            </li>
                            <li>
                                <div>
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 15V49C5 49.5523 5.44772 50 6 50H58C58.5523 50 59 49.5523 59 49V15C59 14.4477 58.5523 14 58 14H32H25.7778H11.2593H6C5.44772 14 5 14.4477 5 15Z" stroke="white" strokeWidth="5" />
                                        <path d="M59 14L33.1094 31.2604C32.4376 31.7083 31.5624 31.7083 30.8906 31.2604L5 14" stroke="white" strokeWidth="5" />
                                    </svg>
                                    {t('Footer.Email')}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <div className="copyright">
            <div className="container">
                &copy; {today.getFullYear()} Residium Software. {t('Footer.AllRightsReserved')}
                <Link className="privacy_polici_btn" to={'/privacy-policy'}>{t('Footer.PrivacyPolici')}</Link>
                <div className="social">
                    <ul>
                        <li>
                            <Link to={'/'}>
                                <img alt="" src="/icons/linkedin_icon.svg" />
                            </Link>
                        </li>
                        <li>
                            <Link to={'/'}>
                                <img alt="" src="/icons/twitter_icon.svg" />
                            </Link>
                        </li>
                        <li>
                            <Link to={'/'}>
                                <img alt="" src="/icons/facebook_icon.svg" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
}