import { useTranslation } from "react-i18next";
import { IServicesData, servicesData } from "../data";
import React from "react";

export const ServicesSection = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { t } = useTranslation();

    return <div className="section_container section_services section_middle" ref={ref}>
        <div className="container">
            <div className="section_title">
                <span>{t('Services.SectionTitle_top')}</span> <br />
                {t('Services.SectionTitle_bottom')}
            </div>

            <div id="services_section" className="section_box_wrapper">
                {servicesData.map((item: IServicesData, index) => {
                    return <div
                        data-aos="flip-left"
			data-aos-offset="300"
                        data-aos-easing="ease-in-out-back"
                        data-aos-anchor="#services_section"
                        data-aos-anchor-position="top-top"
                        data-aos-duration="3000"
                        data-aos-delay={`${(index + 1) * 20}0`}
                        className="box_item" key={index}>
                        <div className="item_icon">
                            <img alt={item.title} src={item.iconUrl} />
                        </div>
                        <div className="item_title">{t(item.title)}</div>
                        <div className="item_value">{t(item.text)}</div>
                    </div>
                })}
            </div>
        </div>
    </div>
})