import { Button, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";

export const AboutUs = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { t } = useTranslation();

    const validationSchema = yup.object({
        name: yup.string().required(t('AboutUs.Validation.Required')),
        email: yup.string().email(t('AboutUs.Validation.InvalidEmail')).required(t('AboutUs.Validation.Required'))
    });

    const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    access_key: '8010b017-cc01-4023-97fe-f54d3a3f6bbd',
                    ...values,
                }),
            });

            if (response.ok) {
                notificationWrapper(t('AboutUs.MessageStatus.Success'));
                resetForm();
            } else {
                notificationWrapper(t('AboutUs.MessageStatus.Error'));
            }
        } catch (error) {
            console.error('Error sending form', error);
            notificationWrapper(t('AboutUs.MessageStatus.Error'));
        }
        setSubmitting(false);
    };

    const notificationWrapper = (text: string) => {
        notification.success({
            message: text,
            description: ``,
            duration: 2,
        })
    }

    return <div className="aboutUs_page" ref={ref}>
        <div className="container">
            <div className="contact_text">
                <div>
                    <h1>{t('AboutUs.Form.Title')}</h1>
                    <h2>{t('AboutUs.Form.SubTitle')}</h2>
                </div>
            </div>
            <div className="contact_form">
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        message: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}>
                    {({ values, handleChange, setFieldValue }) => (
                        <Form>
                            <div className="input_group">
                                <div className="input_control">
                                    <label>{t('AboutUs.Form.FieldName')}</label>
                                    <Field className="ant-input ant-input-lg" name="name" />
                                    <ErrorMessage name="name" className="error_message" component="div" />
                                </div>

                                <div className="input_control">
                                    <label>{t('AboutUs.Form.FieldEmail')}</label>
                                    <Field name="email" />
                                    <ErrorMessage name="email" className="error_message" component="div" />
                                </div>
                            </div>

                            <div className="input_control">
                                <label>{t('AboutUs.Form.FieldMessage')}</label>
                                <TextArea name="message" value={values.message} onChange={(e) => setFieldValue('message', e.target.value)} />
                            </div>

                            <p>
                                {t('AboutUs.Text.First')} <Link className="privacy_polici_btn" to={'/privacy-policy'}>{t('Footer.PrivacyPolici')}</Link> {t('AboutUs.Text.Last')}
                            </p>

                            <div className="submit_btn_row">
                                <Button className="submit_btn" htmlType="submit">
                                    {t('AboutUs.Form.Btn')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    </div>
});