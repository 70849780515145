
export interface IServicesData {
    iconUrl: string;
    title: string;
    text: string;
    link: string;
}

export const servicesData: Array<IServicesData> = [
    {
        iconUrl: "/icons/nearshore_process consulting_icon.svg",
        title: 'Services.GroupItem_1.Title',
        text: 'Services.GroupItem_1.Text',
        link: "service_1"
    },
    {
        iconUrl: "/icons/software_development_icon.svg",
        title: 'Services.GroupItem_2.Title',
        text: 'Services.GroupItem_2.Text',
        link: "service_2"
    },
    {
        iconUrl: "/icons/dedicated_development_teams_icon.svg",
        title: 'Services.GroupItem_3.Title',
        text: 'Services.GroupItem_3.Text',
        link: "service_3"
    },
    {
        iconUrl: "/icons/software_testing_icon.svg",
        title: 'Services.GroupItem_4.Title',
        text: 'Services.GroupItem_4.Text',
        link: "service_4"
    },
    {
        iconUrl: "/icons/maintenance_and_support_icon.svg",
        title: 'Services.GroupItem_5.Title',
        text: 'Services.GroupItem_5.Text',
        link: "service_5"
    }
]

