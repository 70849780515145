/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import './../../helpers/i18n';
import { Dropdown, MenuProps, Modal } from "antd";
import { Menu } from "./menu";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';

export const HeaderComponent = (props: { OnSelectMenuItem: (item: string) => void }) => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const onChangeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const [selectedLng, setSelectedLng] = useState('en');

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const onSelectLng = (lng: string) => {
        setSelectedLng(lng);
        onChangeLanguage(lng);

        if (lng === "lt") {
            navigate(`${location.pathname}?lng=${lng}`, { replace: true });
        } else {
            navigate(`${location.pathname}`, { replace: true });
        }
    }

    useEffect(() => {
        const { language } = i18n;
        if (language !== selectedLng) {
            setSelectedLng(language)
        }
    }, [])

    const items: MenuProps['items'] = [
        {
            label: <span className="language_option en" onClick={() => onSelectLng('en')}>EN</span>,
            key: '0',
        },
        {
            label: <span className="language_option lt" onClick={() => onSelectLng('lt')}>LT</span>,
            key: '1',
        }
    ];

    const onMenuSelect = (ref: string) => {
        props.OnSelectMenuItem(ref);
        setIsModalOpen(false)
    }

    return <header>
        <div className="container">
            <Link to="/" className="logo" onClick={() => props.OnSelectMenuItem("Home")}>
              <img src={'/logo.svg'} alt={'logo'} />
                <div className="name_group">
                    <span className="name">Residium Software</span>
                    <span className="slogan">Business empowered, excellence delivered </span>
                </div>
            </Link>
            <div className="menu">
                <ul>
                    <Menu OnSelectMenuItem={props.OnSelectMenuItem} />
                </ul>
                <Dropdown arrow={true} placement={'top'} menu={{ items }} trigger={['click']}>
                    <div className="language_group">
                        <div className={`language_option ${selectedLng}`}>{selectedLng}</div>
                    </div>
                </Dropdown>
            </div>

            <div className={`mobile_menu_btn`} onClick={() => setIsModalOpen(true)}>
                {isModalOpen ? <MenuOutlined style={{ opacity: '0.3' }} /> : <MenuOutlined />}
            </div>

            <Modal
                title=""
                open={isModalOpen}
                footer={false}
                className="mobile_menu_modal"
                onCancel={() => setIsModalOpen(false)}>
                <ul>
                    <Menu OnSelectMenuItem={onMenuSelect} />
                </ul>

                <div className="language_list">
                    <div className="language_list_wrapper">
                        <div className={`language_option ${selectedLng === 'en' ? 'isActive' : ''}`} onClick={() => onSelectLng('en')}>EN</div>
                        <div className={`language_option ${selectedLng === 'lt' ? 'isActive' : ''}`} onClick={() => onSelectLng('lt')}>LT</div>
                    </div>
                </div>
            </Modal>
        </div>
    </header>
}